<template>
  <div class="popup" v-if="isPopup">
    <div class="adsItem adsItempopupAd">
      <ins
        class="adsbygoogle"
        style="display: block"
        :data-ad-client="this.$store.state.data_ad_client"
        :data-ad-slot="this.$store.state.slot3"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
    <div
      :class="['close', type === 'home' ? 'topClose' : 'bottomClose']"
      @click="clickClose"
      v-if="isClose"
    >
      x
    </div>
  </div>
</template>

<script>
export default {
  props: ['isPopup', "type"],
  data() {
    return {
      isClose: false
    }
  },
  mounted() {
    setTimeout(() => {
      window.addAds();
      this.isClose = true
    }, 1000)
  },
  methods: {
    clickClose() {
      this.$emit('isClickClose', true);
    }
  }
}
</script>

<style lang="scss" >
.popup {
  width: rem(500) !important;
  height: rem(500) !important;
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close {
    width: rem(50);
    height: rem(50);
    border-radius: 50%;
    position: absolute;
    font-size: rem(40);
    text-align: center;
    background: #fff;
  }
  .topClose {
    top: rem(-50);
    left: rem(-50);
  }
  .bottomClose {
    bottom: rem(-50);
    left: 50%;
    transform: translateX(-50%);
  }
  .adsItempopupAd {
    .adsbygoogle {
      width: rem(500) !important;
      height: rem(500) !important;
    }
  }
}
</style>